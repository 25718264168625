import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'

import AccessoryLabel from '~/components/guest/AccessoryLabel'
import SimpleWell from '~/components/msg/SimpleWell'
import SubmitButton from '~/components/SubmitButton'
import TextField from '~/components/TextField'
import Toast from '~/components/Toast'
import { AuthContext } from '~/context/auth'
import Heading from '../Heading'

/**
 * Login
 */

const Login = ({ onSignupClick, onForgotClick, onUpdateProfileClick, onUnVerifiedUserClick }) => {
  const { t } = useTranslation()
  const { login } = useContext(AuthContext)
  const { register, handleSubmit, formState } = useForm()
  const [error, setError] = useState()
  const { message } = useParams()
  const [simplewell, setSimpleWell] = useState()
  const myRef = useRef(null)

  const handleLogin = useCallback(
    async ({ email, password }) => {
      try {
        let user = await login(email, password)
        if (user && user.last_name === 'NEED_COMPLETE_REGISTRATION') {
          onUpdateProfileClick(user)
        }
        if (user && user.verified === 0) {
          onUnVerifiedUserClick(user)
        }
      } catch (error) {
        window.scrollTo(0, 0)
        setError({
          text: error.response && error.response.data ? error.response.data.message : error.response,
          layout: 'red',
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [login]
  )

  useEffect(() => {
    setSimpleWell(message)
  }, [message])

  return (
    <div className="relative" ref={myRef}>
      {!isEmpty(error) && <Toast message={error} layout="red" />}
      <div className="lg:min-h-screen flex pt-16 lg:pt-32 justify-center bg-gray-50 py-12 px-6 lg:px-9">
        <div className="max-w-md w-full">
          <div>
            <Heading level={1} size="xl">
              {t('guest:login.title')}
            </Heading>
            <Heading level={2} size="sm" className="mt-4">
              {t('guest:login.subtitle')}
            </Heading>
          </div>
          <form className="mt-8" onSubmit={handleSubmit(handleLogin)}>
            <div className="mb-6">
              <TextField label={t('guest:login.emailLabel')} name="email" type="text" register={register} />
            </div>
            <div className="-mt-px">
              <TextField
                label={t('guest:login.passwordLabel')}
                name="password"
                type="password"
                register={register}
                accessoryLabel={<AccessoryLabel text={t('guest:login.forgotPassword')} onClick={onForgotClick} />}
              />
            </div>
            {process.env.REACT_APP_EVENT_SIGNUP === 'true' && (
              <div className="mt-6 flex">
                <div onClick={onSignupClick}>
                  <p className="text-sm leading-5 cursor-pointer font-medium text-paragraph focus:outline-none focus:underline">
                    {t('guest:login.signup')}
                  </p>
                </div>
              </div>
            )}
            <div className="mt-6">
              <SubmitButton text={t('guest:login.submit')} isSubmitting={formState.isSubmitting} />
            </div>
          </form>
        </div>
      </div>
      {simplewell && simplewell === 'full_capacity' && (
        <SimpleWell
          title={t('guest:limit.title')}
          subtitle={t('guest:limit.subtitle')}
          btn1={t('guest:limit.click')}
          onBtn1Click={message => setSimpleWell(!message)}
        />
      )}
    </div>
  )
}

/**
 * PropTypes
 */

Login.propTypes = {
  onSignupClick: PropTypes.func.isRequired,
  onForgotClick: PropTypes.func.isRequired,
  onUpdateProfileClick: PropTypes.func.isRequired,
  onUnVerifiedUserClick: PropTypes.func.isRequired,
}

/**
 * Exports
 */

export default Login
