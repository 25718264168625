import React, { useCallback, useState } from 'react'
import { isIE } from 'react-device-detect'

import Forgot from '~/components/guest/Forgot'
import Login from '~/components/guest/Login'
import SignUp from '~/components/guest/SignUp'
import UnsupportedBrowser from '~/components/guest/UnsupportedBrowser'
import VerifyUser from '~/components/guest/UnverifiedUser'
import UpdateProfile from '~/components/guest/UpdateProfile'
import WelcomeUser from '~/components/guest/WelcomeUser'
import GuestLayout from '~/components/layout/GuestLayout'

/**
 * Guest
 */

const Guest = () => {
  const [guest, setGuest] = useState(1)
  const [userToUpdate, setUserToUpdate] = useState({})

  const handleLoginClick = useCallback(() => setGuest(0), [])
  const handleSignupClick = useCallback(() => setGuest(1), [])
  const handleForgotClick = useCallback(() => setGuest(2), [])
  const onUpdateProfileClick = useCallback(user => {
    setUserToUpdate(user)
    setGuest(3)
  }, [])
  const handleUnverifiedUserClick = useCallback(() => setGuest(4), [])
  const handleThanksPage = useCallback(user => {
    setUserToUpdate(user)
    setGuest(5)
  }, [])

  return (
    <GuestLayout>
      {isIE && <UnsupportedBrowser />}
      {!isIE && guest === 0 && (
        <Login
          onSignupClick={handleSignupClick}
          onForgotClick={handleForgotClick}
          onUpdateProfileClick={onUpdateProfileClick}
          onUnVerifiedUserClick={handleUnverifiedUserClick}
        />
      )}
      {!isIE && guest === 1 && (
        <SignUp
          onLoginClick={handleLoginClick}
          onUnVerifiedUserClick={handleUnverifiedUserClick}
          onThanksPageEnable={handleThanksPage}
        />
      )}
      {!isIE && guest === 2 && <Forgot onForgotClick={handleForgotClick} onLoginClick={handleLoginClick} />}
      {!isIE && guest === 3 && <UpdateProfile user={userToUpdate} />}
      {!isIE && guest === 4 && <VerifyUser user={userToUpdate} />}
      {!isIE && guest === 5 && <WelcomeUser user={userToUpdate} />}
    </GuestLayout>
  )
}

/**
 * Exports
 */

export default Guest
