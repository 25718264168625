import React, { Suspense, useCallback, useContext, useState } from 'react'
import PropTypes from 'prop-types'

import Header from '~/components/layout/Header'
import LogoutHandler from '~/components/layout/LogoutHandler'
import Nav from '~/components/layout/Nav'
import Sidebar from '~/components/layout/Sidebar'
import LoadingContent from '~/components/LoadingContent'
import { AuthContext } from '~/context/auth'
import { ChatProvider } from '~/context/chat'
import { SessionManager } from '~/context/session'
import { SocketManager } from '~/context/socketManager'
import { useHistoryChange, useWindowResize } from '~/hooks'
import { getConfig } from '~/lib/config'
import BottomBar from './BottomBar'

/**
 * MainLayout
 */

const MainLayout = ({ children }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(window.innerWidth > 768)
  const [isNavOpen, setNavOpen] = useState(false)
  const { user } = useContext(AuthContext)

  const toggleSidebar = useCallback(() => {
    setSidebarOpen(!isSidebarOpen)
  }, [isSidebarOpen])

  const handleNavButtonClick = useCallback(() => {
    setNavOpen(!isNavOpen)
  }, [isNavOpen])

  const handleNavItemClick = useCallback(() => {
    setNavOpen(false)
  }, [])

  useWindowResize(() => {
    if (window.innerWidth < 768 && isSidebarOpen) {
      setSidebarOpen(false)
    }
  })

  useHistoryChange(() => {
    if (window.innerWidth < 768 && isSidebarOpen) {
      toggleSidebar()
    }
  })

  return (
    <div className="main flex flex-col min-h-screen">
      <SocketManager user={user}>
        <SessionManager user={user}>
          <Header
            onSidebarButtonClick={toggleSidebar}
            onNavButtonClick={handleNavButtonClick}
            isSidebarOpen={isSidebarOpen}
          />
          <div className="flex flex-row flex-1">
            <Nav isOpen={isNavOpen} onItemClick={handleNavItemClick} />
            <div className="content relative flex flex-1 flex-col items-center bg-gray-100">
              <Suspense fallback={<LoadingContent />}>{children}</Suspense>
              <Suspense fallback={null}>
                <BottomBar />
              </Suspense>
            </div>
            {getConfig('general_chat_enable', user) && (
              <ChatProvider>
                <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
              </ChatProvider>
            )}
          </div>
          <LogoutHandler />
        </SessionManager>
      </SocketManager>
      <style jsx>
        {`
          .main {
            min-height: calc(100vh); /* Fallback if browser doesn't support custom properties */
            min-height: calc(var(--vh, 1vh) * 100);
            height: calc(var(--vh, 1vh));
          }

          .content {
            overflow: hidden;
            min-height: calc(100vh - 4.1rem); /* Fallback if browser doesn't support custom properties */
            min-height: calc(var(--vh, 1vh) * 100 - 4.1rem);
            height: calc(var(--vh, 1vh) - 4.1rem);
          }
        `}
      </style>
    </div>
  )
}

/**
 * PropTypes
 */

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

/**
 * Exports
 */

export default MainLayout
