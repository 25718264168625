import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { includes } from 'lodash'
import PropTypes from 'prop-types'

import Dropdown from '~/components/header/Dropdown'
import DropdownButton from '~/components/header/DropdownButton'
import MenuButton from '~/components/header/MenuButton'
import SidebarToggleButton from '~/components/header/SidebarToggleButton'
import { Routes } from '~/constants/routes'
import { eventFolder } from '~/lib/multievent'

/**
 * Helpers
 */

const getSectionName = (pathname, t) => {
  if (includes(pathname, Routes.SESSIONS)) {
    return t(`header:section.${Routes.SESSIONS}`)
  }

  if (includes(pathname, Routes.VOD)) {
    return t(`header:section.${Routes.VOD}`)
  }

  const sectionName = t(`header:section.${pathname}`)

  const isInvalidRoute = includes(sectionName, 'section.')
  if (isInvalidRoute) {
    return t(`header:section.${Routes.HOME}`)
  }

  return sectionName
}

/**
 * Header
 */

const Header = ({ onSidebarButtonClick, onNavButtonClick, isSidebarOpen }) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false)
  const location = useLocation()
  const { t } = useTranslation()
  const sectionName = getSectionName(location.pathname, t)

  const handleOutsidePress = useCallback(() => {
    setDropdownOpen(false)
  }, [])

  const handleDropdownClick = useCallback(() => {
    setDropdownOpen(!isDropdownOpen)
  }, [isDropdownOpen])

  return (
    <nav className="bg-white border-b-2">
      <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
        <div className="relative flex justify-between h-16">
          <div className="absolute inset-y-0 left-0 flex items-center md:hidden">
            <MenuButton onClick={onNavButtonClick} />
          </div>
          <div className="flex-1 md:flex-none flex items-center justify-center md:items-stretch md:justify-start">
            <div className="flex-shrink-0 flex items-center">
              <Link to={Routes.HOME}>
                <img className="max-h-9" src={eventFolder('logo_black.svg')} alt="Logo" />
              </Link>
            </div>
          </div>
          <div className="hidden md:flex items-center">
            <span className={`font-bold text-paragraph ${isSidebarOpen ? 'pr-10' : 'pl-32'}`}>{sectionName}</span>
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 md:static md:inset-auto md:pr-0">
            <div className="flex ml-3 md:ml-0 relative">
              <SidebarToggleButton onClick={onSidebarButtonClick} isSidebarOpen={isSidebarOpen} />
              <DropdownButton onClick={handleDropdownClick} />
            </div>
            <Dropdown
              isOpen={isDropdownOpen}
              onOutsidePress={handleOutsidePress}
              onChatClick={onSidebarButtonClick}
              isSidebarOpen={isSidebarOpen}
            />
          </div>
        </div>
      </div>
    </nav>
  )
}

/**
 * PropTypes
 */

Header.propTypes = {
  onSidebarButtonClick: PropTypes.func.isRequired,
  onNavButtonClick: PropTypes.func.isRequired,
  isSidebarOpen: PropTypes.bool.isRequired,
}

/**
 * Exports
 */

export default Header
