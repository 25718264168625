import React, { useCallback } from 'react'
import { upperFirst } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'

/**
 * Helpers
 */

const getDay = date => moment(date).format('D')

const getDayOfWeek = date =>
  upperFirst(
    moment(date)
      .locale('es')
      .format('dddd')
  )

const getMonth = date =>
  upperFirst(
    moment(date)
      .locale('es')
      .format('MMMM')
  )

/**
 * ProgramTab
 */

const ProgramTab = ({ selected, date, index, onClick }) => {
  const handleClick = useCallback(() => {
    onClick(index)
  }, [index, onClick])

  const day = getDay(date)
  const dayOfWeek = getDayOfWeek(date)
  const month = getMonth(date)

  return (
    <div
      className={`flex flex-1 justify-between items-center my-3 lg:mb-0 mx-2 px-4 py-2 xl:px-6 rounded-lg shadow-md cursor-pointer ${
        selected ? 'bg-primary' : 'bg-gray-100'
      }`}
      onClick={handleClick}
      key={date}
    >
      <div className="hidden lg:block">
        <div
          className={`mr-2 font-heading text-heading text-xl lg:text-base xl:text-2xl ${selected ? 'font-bold' : ''}`}
        >
          {/* Día {index + 1} */}
        </div>
      </div>
      <div className="flex flex-row items-center">
        <div
          className={`${selected ? 'text-white' : 'text-heading'} lg:tracking-wide mr-2 text-3xl xl:text-4xl font-bold`}
        >
          {day}
        </div>
        <div className="right">
          <div className={`${selected ? 'text-white' : 'text-heading'} font-bold text-xs lg:tracking-wide leading-4`}>
            {month}
          </div>
          <div className={`${selected ? 'text-white ' : 'text-dayLabel'} text-xs lg:tracking-wide`}>{dayOfWeek}</div>
        </div>
        <style jsx>{`
          @media (max-width: 768px) {
            .right {
              width: 65px;
            }
          }
        `}</style>
      </div>
    </div>
  )
}

/**
 * PropTypes
 */

ProgramTab.propTypes = {
  selected: PropTypes.bool.isRequired,
  date: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
}

/**
 * Exports
 */

export default ProgramTab
