import React from 'react'
import { useTranslation } from 'react-i18next'

import Heading from '~/components/Heading'
import GuestLayout from '~/components/layout/GuestLayout'
import Paragraph from '~/components/Paragraph'
/**
 * TermsPage
 */

const TermsPage = () => {
  return (
    <GuestLayout>
      <div className="relative">
        <div className="lg:min-h-screen flex pt-16 lg:pt-32 justify-center bg-gray-50 py-12 px-6 lg:px-9">
          <div className="max-w-md w-full">
            <div>
              <Heading level={1} size="xl" className="mb-6">
                {/* {t('terms:title')}*/}TÉRMINOS Y CONDICIONES &amp; POLÍTICA DE PRIVACIDAD
              </Heading>
              <Paragraph className="mt-1">
                {/* <span dangerouslySetInnerHTML={{ __html: t('terms:description') }} /> */}A continuación se
                establecen los términos en que usa y protege la información que es proporcionada por sus usuarios/as en
                el momento de utilizar este sitio web, cuyo titular es la Asociación Española de Escuelas de Segunda
                Oportunidad (en adelante “E2O España”).
                <br />
                <br />
                1. IDENTIFICACIÓN DEL TITULAR DEL DOMINIO
                <br /> El titular de esta web es la ASOCIACIÓN ESPAÑOLA DE ESCUELAS DE SEGUNDA OPORTUNIDAD (en
                adelante”E2O España”), de nacionalidad española, inscrita en el Registro Nacional de Asociaciones y con
                Número de Identificación Fiscal G56045131, con sede en Calle San Francisco de Sales, nº1, 14010 Córdoba,
                España. Correo electrónico de contacto:{' '}
                <a href="mailto:info@e2oespana.org" className="underline">
                  info@e2oespana.org
                </a>{' '}
                <br />
                <br />
                2.- PRIVACIDAD Y TRATAMIENTO DE LA INFORMACIÓN <br />
                2.1.- DERECHO DE INFORMACIÓN <br />
                2.1.1.- Identificación de responsables
                <br /> El responsable del tratamiento es E2O España, que vela por el cumplimiento diligente de la
                normativa de protección de datos de carácter personal. Puede contactar con nuestro Delegado de
                Protección de Datos en la dirección electrónica: 
                <a href="mailto:info@e2oespana.org" className="underline">
                  info@e2oespana.org
                </a>{' '}
                <br />
                <br />
                2.1.2.- Finalidades de tratamiento y base jurídica <br />
                2.1.2.1.- Finalidad general
                <br /> Los datos que nos facilite en los formularios que encontrará en la web, los que nos envíe a
                nuestra dirección de correo electrónico y los que se puedan generar mientras mantenga relación con
                nosotros se incorporarán, tal como nos las ceda, a una base de datos titularidad de E2O ESPAÑA.
                Utilizaremos los datos para prestarle los servicios que nos solicite, en especial su participación en el
                V Encuentro Nacional de Escuelas de Segunda Oportunidad y para enviar información de nuestras
                actividades por correo electrónico. La base jurídica de este tratamiento será la relación que mantenga
                con E2O ESPAÑA y el consentimiento que otorga al aceptar este aviso. <br />
                <br />
                2.1.2.2. Los datos que recopilamos sobre usted
                <br /> Datos personales, o información personal, significa cualquier información sobre una persona a
                partir de la cual se pueda identificar a esa persona. No incluye datos donde se haya eliminado la
                información identificativa (datos anónimos). Podemos recopilar, usar, almacenar y transferir diferentes
                tipos de datos personales sobre usted que hemos agrupado de la siguiente manera: <br /> Datos de
                identidad que incluyen su imagen, nombre, apellidos, entidad, cargo. Esto también puede incluir otro
                contenido audiovisual en el que aparezcas cuando contribuyas o interactúes en un evento. <br /> Datos de
                contacto que incluyen su correo electrónico. <br /> Datos de perfil que incluyen su nombre de usuario y
                contraseña, comentarios, respuestas a encuestas. <br /> Datos técnicos que incluyen su dirección de
                protocolo de Internet (IP), sus datos de inicio de sesión, tipo y versión del navegador, información de
                hardware, configuración y ubicación de la zona horaria, tipos y versiones de complementos del navegador,
                sistema operativo y sitio web, y otra tecnología en los dispositivos que usted utilizar para acceder a
                la plataforma. <br /> Datos de uso que incluyen información sobre cómo utiliza nuestros Servicios, así
                como las plataformas de terceros que ha vinculado a su uso de nuestros Servicios. Los datos de uso
                incluyen metadatos que brindan un contexto adicional sobre la forma en que se utilizan los Servicios,
                como la duración de la visita, las visitas a la página, las rutas de navegación y la información de
                interacción de la página, así como información sobre el tiempo, la frecuencia y el patrón de uso. <br />{' '}
                Contenido generado por el usuario que incluye datos que envía como parte de un Evento o como resultado
                de sus interacciones con la plataforma, como materiales del Evento, videos, chats y mensajes. También
                recopilamos, usamos y compartimos datos agregados, como datos estadísticos o demográficos, para
                cualquier propósito. Los datos agregados pueden derivarse de sus datos personales, pero no se consideran
                datos personales, ya que estos datos no revelarán su identidad directa o indirectamente. <br />
                <br />
                2.1.2.3.- Tratamiento de imágenes.
                <br /> El responsable del tratamiento documenta los eventos de carácter público que organiza con
                fotografías y vídeos con el fin de hacer difusión en su web y otros espacios de difusión pública de
                información como son: la propia web, las redes sociales donde E2O España disponga de perfil creado y
                publicaciones propias o prensa. Puede obtener más información sobre este apartado contactando con el
                Delegado de Protección de Datos (DPO). El tratamiento de estas imágenes está legitimado con el
                consentimiento de los afectados o en aplicación del artículo 8 de la LO 1/1982 de protección del derecho
                al honor, la intimidad y la propia imagen. <br />
                <br />
                2.1.3.- Categorías de destinatarios.
                <br /> Sus datos podrán ser cedidos a los destinatarios que se indican a continuación, por los motivos
                que a continuación se explican: <br /> El personal debidamente autorizado por la dirección del
                responsable del tratamiento. <br /> La administración pública en el ámbito de sus competencias. <br />{' '}
                Aquellas cesiones a terceros que sean necesarias para cumplir con las obligaciones derivadas de las
                actividades e iniciativas en las que voluntariamente participes. <br /> Proveedores que precisen acceder
                a sus datos para la prestación de servicios que E2O España o sus entidades socias hayan contratado.
                Puede ampliar esta información consultando a{' '}
                <a href="mailto:info@e2oespana.org" className="underline">
                  info@e2oespana.org
                </a>{' '}
                <br />
                <br />
                2.1.4.- Plazo de conservación de la información
                <br /> El responsable del tratamiento conservará su información mientras no revoque el consentimiento
                otorgado en aceptar esta cláusula. <br />
                <br />
                2.1.5.- Derechos como afectado/a y control de su información personal
                <br /> Tiene derecho a acceder, rectificar, suprimir, oponerse a que sus datos se traten, a limitar el
                tratamiento, a pedir la portabilidad de los datos, a no ser sometido a decisiones individuales
                automatizadas y a revocar el consentimiento que otorga. Puede ejercitar sus derechos de acceso,
                rectificación, supresión y portabilidad, limitación y/u oposición al tratamiento, a través de la
                dirección electrónica
                <a href="mailto:info@e2oespana.org" className="underline">
                  info@e2oespana.org
                </a>{' '}
                <br />
                <br />
                2.1.6.- Obligaciones del afectado/a
                <br /> El afectado/a debe aportar información veraz y actualizada en todos los procesos de recogida de
                datos, siendo él el único responsable de la vulneración de esta obligación. En los formularios de
                recogida de datos están marcadas los datos que son obligatorias aportar, en función de la demanda que
                haga el afectado/a. La falta de la aportación de estos datos puede suponer la imposibilidad de
                participar de la actividad o proporcionar el servicio que se solicita. <br />
                <br />
                2.2. CONSENTIMIENTO DEL USUARIO
                <br /> Se entiende que el usuario/a acepta las condiciones establecidas si pulsa los botones de
                confirmación, aceptación o registro de los formularios de recogida de datos, si envía un mensaje por
                correo electrónico y si participa en el chat o en encuestas. Los datos personales se guardan en la base
                de datos general de E2O España. El usuario/a acepta que todo lo que publica en el chat se hará público.
                E2O ESPAÑA podrá llevar a cabo y borrar contenidos del usuario/a que atenten contra los principios
                mencionados en el artículo 3.1. Los datos de perfil del usuario/a (nombre apellidos imagen entidad
                cargo) se harán también público en caso de tener desactivado el “modo oculto” en la plataforma. <br />
                <br />
                2.3. - USO DE DIRECCIONES IP
                <br /> Para facilitar la búsqueda de recursos que creemos que son de su interés, puede encontrar en este
                sitio web enlaces a otras páginas. Una vez que clique en estos enlaces y abandone nuestra página, ya no
                tenemos control sobre al sitio al que es redirigido. Esta política de privacidad sólo es de aplicación
                al sitio web www.encuentroe2o.org. El responsable del tratamiento no garantiza el cumplimiento de esta
                política en otras webs, ni se hace responsable de los accesos mediante enlaces desde este sitio. Dichos
                sitios están sujetos a sus propias políticas de privacidad por lo cual es recomendable que los consulte
                para confirmar que usted está de acuerdo con éstas. <br />
                <br />
                2.4.- COOKIES Y RECOGIDA DE DATOS ESTADÍSTICOS
                <br /> La navegación de un usuario/a por esta web deja como rastro la dirección IP que le ha sido
                asignada por su proveedor de acceso a Internet. Sólo se utiliza esta dirección para fines exclusivamente
                internos, como son las estadísticas de acceso al Portal. Para la utilización de esta web, es posible que
                se utilicen cookies. Las cookies que se puedan utilizar asocian únicamente con el navegador de un
                ordenador determinado (un usuario/a anónimo), y no proporcionan por sí mismas ningún dato personal del
                usuario/a. Este sitio web utiliza cookies. Utilizamos cookies para personalizar el contenido y los
                anuncios, ofrecer funciones de medios sociales y analizar el tráfico del sitio. También compartimos la
                información sobre cómo utilizar nuestro sitio con los partners de medios sociales, de publicidad y de
                análisis con las que colaboramos. A su vez, ellos la pueden combinar con otros datos que los haya
                proporcionado o hayan recopilado a partir del uso que ha hecho de sus servicios. Las cookies son
                pequeños archivos de texto que los sitios web pueden utilizar para que el usuario/a pueda hacer un uso
                más eficiente. La ley establece que podemos almacenar cookies en su dispositivo si son estrictamente
                necesarias para el funcionamiento de este sitio. Para todos los otros tipos de cookies necesitamos su
                permiso. Este sitio web utiliza diferentes tipos de cookies. En alguna ocasión, las cookies que aparecen
                en nuestras páginas provienen de servicios de terceros. En cualquier momento, usted podrá cambiar o
                retirar su consentimiento de la Declaración sobre uso de cookies en nuestro sitio web. Por favor, en el
                momento de contactar con nosotros en relación a su consentimiento, haga constar su identificación y la
                fecha. Su consentimiento se aplica al dominio www.encuentroe2o.org. El usuario/a tiene la posibilidad de
                configurar su navegador para ser avisado en pantalla de la recepción de cookies y para evitar su
                instalación en su disco duro. Todos los navegadores permiten hacer cambios con el fin de desactivar la
                configuración de las cookies. Estos ajustes se encuentran ubicados en las &quot;opciones&quot; o
                &quot;preferencias&quot; del menú de su navegador. A continuación podrá encontrar los links de cada
                navegador para desactivar las cookies siguiendo las instrucciones:
                <br />
                Chrome: http://support.google.com/chrome/bin/answer.py?hl=es&amp;answer=95647
                <br />
                Safari: http://support.apple.com/kb/ph5042
                <br />
                Opera: http://www.opera.com/help/tutorials/security/privacy/ <br />
                Le informamos, sin embargo, que existe la posibilidad de que la desactivación de alguna cookie impida o
                dificulte la navegación o la prestación de los servicios ofrecidos en esta web.
                <br />
                <br />
                3. NORMAS DE USO DEL PORTAL
                <br /> Quien acceda y use este sitio web, tendrá la consideración de usuario/a. El usuario/a por el solo
                hecho de serlo, acepta todas las condiciones de uso. <br />
                <br />
                3.1. GENÉRICAS
                <br /> Está prohibido publicar en esta web y herramientas de participación de contenidos que atenten
                contra los siguientes principios: a) La salvaguarda del orden público, la investigación penal, seguridad
                pública y defensa nacional. b) La protección de la salud pública o de las personas físicas que tengan la
                condición de consumidores o usuarios/as. c) El respeto a la dignidad de la persona y al principio de no
                discriminación por motivos de raza, sexo, opinión, nacionalidad, discapacidad o cualquier otra
                circunstancia personal o social. d) La protección de la juventud y de la infancia. e) El respeto a la
                autonomía de la voluntad del afectado/a. f) El respeto a la propiedad intelectual de lo publicado. g) En
                general, el respeto a la legalidad vigente. <br />
                <br />
                3.2. PARTICULARES
                <br /> El usuario/a asume la responsabilidad del uso del portal. Esta responsabilidad se extiende al
                registro que fuese necesario para acceder a determinados servicios o contenidos. El usuario/a será
                responsable de aportar información veraz y lícita en el proceso de registro. El usuario/a se compromete
                a hacer un uso adecuado de los contenidos y servicios que el titular del dominio ofrece a través de sus
                websites y herramientas de participación; con carácter enunciativo pero no limitativo, también se
                compromete a no usarlas para: <br /> Provocar daños en los sistemas físicos y lógicos del responsable
                del tratamiento, de sus proveedores o de terceras personas, introducir o difundir en la red virus
                informáticos o cualesquiera otros sistemas físicos o lógicos que sean susceptibles de provocar daños.{' '}
                <br /> Intentar acceder y, en su caso, utilizar las cuentas de correo electrónico de otros usuarios/as y
                modificar o manipular sus mensajes. El titular del dominio se reserva el derecho de retirar todos los
                comentarios y aportaciones que vulneren el respeto a la dignidad de la persona, que sean
                discriminatorios, xenófobos, racistas, pornográficos, que atenten contra las personas, especialmente,
                contra los intereses de la juventud o de la infancia, el orden o la seguridad pública o que, no
                resultaran adecuados para su publicación a criterio de la dirección del centro. En cualquier caso, el
                titular del dominio no es responsable de las opiniones vertidas por los usuarios/as a través de los
                foros, chats, u otras herramientas de participación. <br />
                <br />
                3.3. USO DE CONTRASEÑAS.
                <br /> Para acceder a determinados contenidos hay que introducir un correo electrónico y una contraseña.
                Si no dispone de una, la puede obtener al registrarse. Las contraseñas son personales e intransferibles.
                El usuario/a es el único responsable de las consecuencias que se puedan derivar del uso de su
                contraseña. Para cualquier incidencia con las contraseñas puede seguir las instrucciones que encontrará
                en un link en la misma pantalla de identificación. <br />
                <br />
                4. PROPIEDAD INTELECTUAL E INDUSTRIAL
                <br /> El titular del dominio es titular de todos los derechos de propiedad intelectual e industrial de
                sus páginas web, como también lo es de todo su contenido, por lo que toda reproducción, distribución y
                comunicación pública total o parcial de su contenido queda expresamente prohibida sin la autorización
                expresa de la dirección del centro. <br />
                <br />
                5. RESPONSABILIDAD
                <br /> El titular del dominio no se hace responsable de los daños y perjuicios que se puedan derivar de
                interferencias o averías telefónicas, desconexiones en el sistema electrónico, presencia de virus
                informáticos, programas maliciosos o cualquier otro factor ajeno a su control. El titular del dominio se
                reserva el ejercicio de las acciones legales que correspondan contra los que vulneren estas condiciones.{' '}
                <br />
                <br />
                6. MODIFICACIONES DE LAS PRESENTES CONDICIONES Y VIGENCIA E2O
                <br /> España puede modificar estas condiciones en cualquier momento, sin previo aviso, publicando sus
                modificaciones tal como aparecen. <br />
                <br />
                7. LEGISLACIÓN APLICABLE Y JURISDICCIÓN
                <br /> La relación entre el titular del dominio y el usuario/a se regirá por la normativa española
                vigente y cualquier controversia se someterá a los Juzgados y tribunales que corresponda.
              </Paragraph>
            </div>
          </div>
        </div>
      </div>
    </GuestLayout>
  )
}

/**
 * Exports
 */

export default TermsPage
