import React from 'react'
import { orderBy } from 'lodash'
import PropTypes from 'prop-types'

import HostNames from '~/components/global/HostsNames'

/**
 * HostsAvatars
 */

const HostsAvatars = ({ hosts, showPics, showNames }) => {
  let sortedHost = orderBy(hosts, ['last_name'], ['asc'])
  sortedHost = orderBy(sortedHost, ['moderator'], ['asc'])

  if (!showPics && !showNames) {
    return null
  }

  return (
    <div className="flex w-full flex-col lg:flex-row ">
      <div>
        {showPics &&
          sortedHost.map((host, index) => (
            <img
              key={index}
              className={`relative z-${50 - index * 10} ${
                index > 0 ? '-ml-6' : '-ml-1'
              }  inline-block h-11 w-11 box-content rounded-full object-cover text-white border-4 border-gray-100 shadow-solid`}
              src={host.image}
              alt={host.name}
            />
          ))}
      </div>
      {showNames && (
        <div className={`mb-3 mt-1 lg:m-3 ${!showPics && 'lg:ml-0'} `}>
          <HostNames sortedHost={sortedHost} />
        </div>
      )}
    </div>
  )
}

/**
 * PropTypes
 */

HostsAvatars.propTypes = {
  hosts: PropTypes.array.isRequired,
  showPics: PropTypes.bool,
  showNames: PropTypes.bool,
}

/**
 * Exports
 */

export default HostsAvatars
