import React from 'react'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'

import Heading from '~/components/Heading'
import HostsAvatars from '~/components/HostsAvatars'
import Paragraph from '~/components/Paragraph'
import { useAuth } from '~/context/auth'
import { showConfig } from '~/lib/config'

/**
 * VideoInfo
 */

const VideoInfo = ({ title, description, hosts }) => {
  const { user } = useAuth()

  return (
    <div>
      <div className="flex items-center mb-4">
        <Heading level={1} size="md">
          {title}
        </Heading>
      </div>
      <div className="flex items-center mb-4">
        <div className="flex relative z-0 overflow-hidden">
          {!isEmpty(hosts) && (
            <HostsAvatars
              hosts={hosts}
              showPics={!!showConfig('stage_showhostpics', user)}
              showNames={!!showConfig('stage_showhostnames', user)}
            />
          )}
        </div>
      </div>
      <div className="flex items-center mb-4">
        <Paragraph className="mt-1">
          <span dangerouslySetInnerHTML={{ __html: description }} />
        </Paragraph>
      </div>
    </div>
  )
}

/**
 * PropTypes
 */

VideoInfo.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  hosts: PropTypes.array,
}

/**
 * Exports
 */

export default VideoInfo
