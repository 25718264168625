import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { includes, isEmpty } from 'lodash'
import PropTypes from 'prop-types'

import ChatInbox from '~/components/sidebar/ChatInbox'
import ChatPeople from '~/components/sidebar/ChatPeople'
import ChatRoom from '~/components/sidebar/ChatRoom'
import ChatSupport from '~/components/sidebar/ChatSupport'
import HiddenModeMessage from '~/components/sidebar/HiddenModeMessage'
import SidebarButton from '~/components/sidebar/SidebarButton'
import SidebarTab from '~/components/sidebar/SidebarTab'
import { Routes } from '~/constants/routes'
import { AuthContext } from '~/context/auth'
import { useChat } from '~/context/chat'
import { getConfig } from '~/lib/config'

/**
 * Helpers
 */

const getTabLabels = t => ({
  CHAT: t('sidebar:chat.title'),
  INBOX: t('sidebar:inbox.title'),
  PEOPLE: t('sidebar:people.title'),
})

/**
 * Sidebar
 */

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const { t } = useTranslation()
  const tabLabels = getTabLabels(t)
  const { user, cometChatUser } = useContext(AuthContext)
  const isShadowUser = cometChatUser.role === 'shadow'
  const initialTab = isShadowUser ? tabLabels.PEOPLE : tabLabels.INBOX
  const [selectedTab, setSelectedTab] = useState(initialTab)
  const location = useLocation()
  const { unreadMessageCount, selectedConversation, setSelectedConversation } = useChat()

  const isSupportRoute = location.pathname === Routes.SUPPORT
  const isChatSection =
    location.pathname === Routes.PLENARY ||
    includes(location.pathname, '/sesiones/vivo/') ||
    includes(location.pathname, '/sesiones/stream/')
  const isChatSelected = selectedTab === tabLabels.CHAT
  const isInboxSelected = selectedTab === tabLabels.INBOX
  const isPeopleSelected = selectedTab === tabLabels.PEOPLE
  const statusClassNames = isOpen ? 'md:block' : 'md:hidden'
  const isChatActive = user.inbox_active && getConfig('general_chat_enable', user)

  useEffect(() => {
    if (isChatSelected && !isChatSection) {
      setSelectedTab(isShadowUser ? tabLabels.PEOPLE : tabLabels.INBOX)
    }
  }, [isChatSection, isChatSelected, isShadowUser, location, tabLabels.INBOX, tabLabels.PEOPLE])

  useEffect(() => {
    if (!isInboxSelected && !isEmpty(selectedConversation)) {
      setSelectedConversation({})
    }
  }, [isInboxSelected, selectedConversation, setSelectedConversation])

  useEffect(() => {
    if (isChatSection) {
      setSelectedTab(tabLabels.CHAT)
    }
  }, [isChatSection, location.pathname, tabLabels.CHAT])

  const handleChatClick = useCallback(() => {
    toggleSidebar()
    setSelectedTab(tabLabels.CHAT)
  }, [tabLabels.CHAT, toggleSidebar])

  const handleInboxClick = useCallback(() => {
    toggleSidebar()
    setSelectedTab(tabLabels.INBOX)
  }, [tabLabels.INBOX, toggleSidebar])

  const handlePeopleClick = useCallback(() => {
    toggleSidebar()
    setSelectedTab(tabLabels.PEOPLE)
  }, [tabLabels.PEOPLE, toggleSidebar])

  return (
    <>
      {!isOpen && (
        <div className="hidden md:block fixed md:static small-sidebar border-l-2">
          <nav className="flex flex-col items-center mt-2 bg-white">
            {isChatSection && (
              <SidebarButton label="Muro" onClick={handleChatClick} imgSrc="/images/icon-sidebar-feed.svg" />
            )}
            {!isShadowUser && (
              <SidebarButton
                label="Chat"
                onClick={handleInboxClick}
                imgSrc="/images/icon-sidebar-chat.svg"
                unreadMessageCount={unreadMessageCount}
              />
            )}
            <SidebarButton label="Participantes" onClick={handlePeopleClick} imgSrc="/images/icon-sidebar-people.svg" />
          </nav>
          <style jsx>{`
            @media (min-width: 768px) {
              .small-sidebar {
                width: 80px;
              }
            }
          `}</style>
        </div>
      )}
      {isOpen && (
        <div className={`fixed md:static ${statusClassNames} sidebar border-l-2 bg-chatBackground`}>
          <div
            className={`navigation border-b border-gray-200 bg-white ${
              isSupportRoute ? 'flex justify-center items-center' : ''
            }`}
          >
            <nav className="flex">
              {!isSupportRoute && (
                <>
                  {isChatSection && (
                    <SidebarTab selected={isChatSelected} label={tabLabels.CHAT} onClick={setSelectedTab} />
                  )}
                  {!isShadowUser && (
                    <SidebarTab
                      selected={isInboxSelected}
                      label={tabLabels.INBOX}
                      onClick={setSelectedTab}
                      unreadMessageCount={unreadMessageCount}
                    />
                  )}
                  <SidebarTab selected={isPeopleSelected} label={tabLabels.PEOPLE} onClick={setSelectedTab} />
                </>
              )}
              {isSupportRoute && (
                <span className="text-base font-bold text-paragraph">{t('sidebar:support.title')}</span>
              )}
            </nav>
          </div>

          {!isChatActive && !isSupportRoute ? <HiddenModeMessage /> : null}
          {isChatActive && isChatSelected && !isSupportRoute ? <ChatRoom /> : null}
          {isChatActive && isInboxSelected && !isSupportRoute ? <ChatInbox /> : null}
          {isChatActive && isPeopleSelected && !isSupportRoute ? <ChatPeople /> : null}
          {isChatActive && isSupportRoute ? <ChatSupport /> : null}

          <style jsx>{`
            .navigation {
              height: 54px;
            }

            .sidebar {
              width: 100%;
              left: ${isOpen ? 0 : window.innerWidth}px;
            }

            @media (min-width: 768px) {
              .sidebar {
                width: 350px;
                left: unset;
              }
            }
          `}</style>
        </div>
      )}
    </>
  )
}

/**
 * Exports
 */

Sidebar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
}

/**
 * Exports
 */

export default Sidebar
