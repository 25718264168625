import React, { useCallback, useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'

import SubmitButton from '~/components/SubmitButton'
import TextField from '~/components/TextField'
import Toast from '~/components/Toast'
import { AuthContext } from '~/context/auth'
import Heading from '../Heading'
import ParagraphHTML from '../ParagraphHTML'

/**
 * WelcomeUser
 */

const WelcomeUser = ({ user }) => {
  console.log('user', user)

  const { t } = useTranslation()
  const { welcomeUser } = useContext(AuthContext)
  const [error, setError] = useState()
  const { register, handleSubmit, formState } = useForm({
    defaultValues: {
      email: user.email,
      token: user.token,
    },
  })

  const handleSignup = useCallback(
    async ({ email, token }) => {
      try {
        await welcomeUser(email, token)
      } catch (error) {
        setError({ text: error.response.data.message, layout: 'red' })
      }
    },
    [welcomeUser]
  )

  return (
    <div className="relative">
      {!isEmpty(error) && <Toast message={error} layout="red" />}
      <div className="min-h-screen flex justify-center items-center py-12 bg-gray-50 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full">
          <Heading level={1} size="xl" className="mt-6">
            Tu inscripción está confirmada
          </Heading>
          <ParagraphHTML className="mt-4">
            Próximamente estará disponible más información. Mientras tanto te invitamos a seguirnos y estar atentos/as a
            nuestras redes sociales donde compartiremos todas las actualizaciones del encuentro.
            <br />
            Para cualquier duda o pregunta: info@e2oespana.org.
            <br />
            <br />
            ¡Nos vemos online el día 29 de abril!
          </ParagraphHTML>
          <br></br>
          <form className="mt-8" onSubmit={handleSubmit(handleSignup)}>
            <input type="hidden" name="remember" value="true" />
            <div className="">
              <TextField label="" name="email" type="hidden" register={register} />
              <TextField label="" name="token" type="hidden" register={register} />
            </div>

            <div className="mt-6">
              <SubmitButton text="Accede" isSubmitting={formState.isSubmitting} />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

/**
 * Exports
 */

WelcomeUser.propTypes = {
  user: PropTypes.object.isRequired,
}

/**
 * Exports
 */

export default WelcomeUser
