import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'

/**
 * HostNames
 */

const funcSep = function(str) {
  return str.name && str.name.charAt(0) === 'I' ? ' e ' : ' y '
}
const HostNames = ({ sortedHost }) => {
  if (isEmpty(sortedHost)) {
    return null
  }

  let stringHost = ''
  for (let i = 0; i < sortedHost.length; i++) {
    if (i === sortedHost.length - 1 && sortedHost.length > 1) {
      if (sortedHost[i].moderator === 1) {
        stringHost += '. Modera: '
      } else {
        stringHost += funcSep(sortedHost[i])
      }
    }
    stringHost += sortedHost[i].name
    if (i < sortedHost.length - 3) {
      stringHost += ', '
    } else if (i < sortedHost.length - 2 && sortedHost[i + 2].moderator === 1) {
      stringHost += funcSep(sortedHost[i + 1])
    } else if (i < sortedHost.length - 2) {
      stringHost += ', '
    }
  }

  return stringHost
}
/**
 * PropTypes
 */

HostNames.propTypes = {
  sortedHosts: PropTypes.array,
}

/**
 * Exports
 */

export default HostNames
