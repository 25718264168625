import React, { useCallback, useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'

import SubmitButton from '~/components/SubmitButton'
import TextField from '~/components/TextField'
import Toast from '~/components/Toast'
import { AuthContext } from '~/context/auth'
import Heading from '../Heading'

/**
 * SignUp
 */

const SignUp = ({ onLoginClick, onUnVerifiedUserClick, onThanksPageEnable }) => {
  const { t } = useTranslation()
  const { signup } = useContext(AuthContext)
  const [error, setError] = useState()
  const { register, handleSubmit, formState } = useForm({
    defaultValues: {
      first_name: '',
      last_name: '',
      company_fantasy: '',
      company_role: '',
      email: '',
      password: '',
      externalId: 'App Sign Up',
    },
  })

  const handleSignup = useCallback(
    async ({ first_name, last_name, company_fantasy, company_role, email, password, externalId }) => {
      try {
        let user = await signup(first_name, last_name, company_fantasy, company_role, email, password, externalId)
        console.log('USER2', first_name, last_name, user)
        if (user && user.verified === 0) {
          onUnVerifiedUserClick(user)
        }
        if (user && user.Event.thanks_enable === 1) {
          onThanksPageEnable(user)
        }
      } catch (error) {
        setError({ text: error.response.data.message, layout: 'red' })
      }
    },
    [onThanksPageEnable, onUnVerifiedUserClick, signup]
  )

  return (
    <div className="relative">
      {!isEmpty(error) && <Toast message={error} layout="red" />}
      <div className="min-h-screen flex justify-center items-center py-12 bg-gray-50 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full">
          <Heading level={1} size="xl" className="mt-6">
            {t('guest:signup.title')}
          </Heading>
          <form className="mt-8" onSubmit={handleSubmit(handleSignup)}>
            <input type="hidden" name="remember" value="true" />
            <div className="mb-4">
              <TextField label={t('guest:signup.firstNameLabel')} name="first_name" type="text" register={register} />
            </div>
            <div className="mb-4">
              <TextField label={t('guest:signup.lastNameLabel')} name="last_name" type="text" register={register} />
            </div>
            <div className="mb-4">
              <TextField
                label={t('guest:signup.companyLabel')}
                name="company_fantasy"
                type="text"
                register={register}
              />
            </div>
            <div className="mb-4">
              <TextField
                label={t('guest:signup.companyRoleLabel')}
                name="company_role"
                type="text"
                register={register}
              />
            </div>
            {/* <div className="mb-4">
              <TextField
                label={t('guest:signup.companyLabel')}
                name="company_fantasy"
                type="text"
                register={register}
              />
            </div> */}
            {/* <div className="mb-4">
              <div className="flex justify-between items-center mb-2 text-xs">
                <label className="block text-base font-normal leading-5 text-paragraph">
                  {t('guest:signup.companyLabel')}
                </label>
              </div>
              <div className="mt-1 relative rounded-md">
                <select
                  id="company_fantasy"
                  name="company_fantasy"
                  ref={register}
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                >
                  <option>USA</option>
                  <option selected>Canada</option>
                  <option>EU</option>
                </select>
              </div>
            </div>
            <div className="mb-4">
              <TextField
                label={t('guest:signup.companyRoleLabel')}
                name="company_role"
                type="text"
                register={register}
              />
            </div> */}
            <div className="mb-4">
              <TextField label={t('guest:signup.emailLabel')} name="email" type="email" register={register} />
            </div>
            <TextField label={t('guest:signup.passwordLabel')} name="password" type="password" register={register} />
            <div>
              <div className="mt-8 flex rounded-md font-medium text-sm leading-5">
                <input
                  name="privacidad"
                  type="checkbox"
                  required
                  className="form-checkbox w-6 inline-block py-3 sm:text-base sm:leading-5"
                  ref={register}
                />{' '}
                <span className="ml-3 mt-1">
                  Acepto los{' '}
                  <a href="/privacidad" target="_blank" rel="noopener noreferrer" className="underline">
                    términos y condiciones & la política de privacidad
                  </a>
                </span>
              </div>
            </div>

            <div className="mt-6 flex">
              <div className="text-sm leading-5" onClick={onLoginClick}>
                <p className="cursor-pointer font-medium text-paragraph focus:outline-none focus:underline">
                  {t('guest:signup.login')}
                </p>
              </div>
            </div>
            <div className="mt-6">
              <SubmitButton text={t('guest:signup.submit')} isSubmitting={formState.isSubmitting} />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

/**
 * Exports
 */

SignUp.propTypes = {
  onLoginClick: PropTypes.func.isRequired,
  onUnVerifiedUserClick: PropTypes.func.isRequired,
  onThanksPageEnable: PropTypes.func.isRequired,
}

/**
 * Exports
 */

export default SignUp
