import bitbang from './bitbang'
import encuentroe2o from './encuentroe2o'
import es from './es'
import mondelez from './mondelez'

export default {
  es,
  bitbang,
  mondelez,
  encuentroe2o,
}
