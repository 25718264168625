import React, { useEffect } from 'react'
import { isNil } from 'lodash'

import { forceMutateMainStage, useMainStage } from '~/api'
import PageContainer from '~/components/layout/PageContainer'
import VideoPlayer from '~/components/mainstage/VideoPlayer'
import VideoInfo from '~/components/VideoInfo'
import { useSocketManager } from '~/context/socketManager'

/**
 * MainStage
 */

const MainStage = () => {
  const { agenda } = useMainStage(process.env.REACT_APP_EVENT_ID)
  const { mainStageLive, socketEmit } = useSocketManager()
  const { title, description, Hosts } = agenda

  useEffect(() => {
    if (mainStageLive.id && (mainStageLive.id !== agenda.id || mainStageLive.online !== agenda.online)) {
      forceMutateMainStage(process.env.REACT_APP_EVENT_ID)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainStageLive.id, mainStageLive.online])

  useEffect(() => {
    setTimeout(function() {
      forceMutateMainStage(process.env.REACT_APP_EVENT_ID)
      socketEmit('pollUpdate', { event: process.env.REACT_APP_EVENT_ID, agenda: agenda.id })
      socketEmit('sidebarmessageUpdate', { event: process.env.REACT_APP_EVENT_ID, agenda: agenda.id })
    }, 800)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PageContainer className={`${isNil(agenda.start_date) ? 'md:mb-24' : 'md:mb-24'}`}>
      <VideoPlayer agenda={agenda} />
      <VideoInfo title={title} description={description} hosts={Hosts} />
    </PageContainer>
  )
}

/**
 * Exports
 */

export default MainStage
