import moment from 'moment'

moment.locale('es')

/**
 * API Hooks
 */

export const showConfig = (parameter, user) => {
  return user && user.Event && user.Event.config && user.Event.config[parameter] > 0
}

export const getConfig = (parameter, user) => {
  return user && user.Event && user.Event.config && user.Event.config[parameter] ? user.Event.config[parameter] : ''
}

export const txtConvertSessionGroup = sessions => {
  if (sessions.length === 0) {
    return {}
  }

  let result = {
    title: 'Talleres simultáneos',
    start_date: sessions[0].start_date,
    end_date: sessions[0].end_date,
    subtitle: '<ul class="list-disc pl-4">',
  }
  for (let i = 0; i < sessions.length; i++) {
    result.subtitle = result.subtitle + '<li>' + sessions[i].title + '. ' + sessions[i].subtitle + '</li>'
  }
  result.subtitle = result.subtitle + '</ul>'

  return result
}
