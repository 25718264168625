import React from 'react'
import Slider from 'react-slick'
import PropTypes from 'prop-types'

import ProgramTab from './ProgramTab'

/**
 * ProgramTabs
 */

const ProgramTabs = ({ dates, selectedDate, onTabClick }) => {
  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
    infinite: false,
    initialSlide: selectedDate,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  }

  return (
    <div className="py-2">
      {/* <Slider {...settings}> */}
      {dates.map((date, index) => (
        <ProgramTab key={date} date={date} selected={index === selectedDate} onClick={onTabClick} index={index} />
      ))}
      {/* </Slider> */}
    </div>
  )
}

/**
 * PropTypes
 */

ProgramTabs.propTypes = {
  dates: PropTypes.array.isRequired,
  selectedDate: PropTypes.number.isRequired,
  onTabClick: PropTypes.func.isRequired,
}

/**
 * Exports
 */

export default ProgramTabs
